import priceJS from "../utils/price.json";
import { findObjectInArray } from "./object";
import convert from "color-convert";
import { isNull } from "./validation";
//unitPrice:{}
export const calculateDependencyTotal = (quantity, priceTable) => {
  // let tempMax = priceTable[i]["rangeMax"];
  let itemPrice = 0;
  for (let i = 0; i < priceTable.length; i++) {
    // fix maximum price value
    if (priceTable[i]["rangeMax"] < 0) {
      priceTable[i]["rangeMax"] = Number.MAX_SAFE_INTEGER;
    }
    // get current unit price
    if (
      quantity >= priceTable[i]["rangeMin"] &&
      quantity < priceTable[i]["rangeMax"]
    ) {
      itemPrice = Object.values(priceTable[i])[0];
      break;
    }
  }
  let total = itemPrice * quantity;
  return total;
};

//imgFolder for custom product image change only
export const calculateTotal = (
  quantity,
  priceTable,
  variants,
  setupFee,
  imgFolder
) => {
  let itemPrice = 0;
  //get sizes
  const size =
    findObjectInArray(variants, "Size") ||
    findObjectInArray(variants, "Plain Stock Bib Size") ||
    findObjectInArray(variants, "Custom Tearoff Bib Size");
  if (!isNull(size)) {
    //this product has size
    const matchingObject = priceTable.find((item) => {
      return (
        item.variant_name === size &&
        quantity >= item.rangeMin &&
        (item.rangeMax === -1 || quantity < item.rangeMax)
      );
    });
    itemPrice = matchingObject.cad;
  } else {
    //this product no size
    const matchingObject = priceTable.find((item) => {
      return (
        quantity >= item.rangeMin &&
        (item.rangeMax === -1 || quantity < item.rangeMax)
      );
    });
    itemPrice = matchingObject.cad;
  }

  // for (let i = 0; i < priceTable.length; i++) {
  //   // fix maximum price value
  //   if (priceTable[i]["rangeMax"] < 0) {
  //     priceTable[i]["rangeMax"] = Number.MAX_SAFE_INTEGER;
  //   }

  //   // get current unit price
  //   if (
  //     quantity >= priceTable[i]["rangeMin"] &&
  //     quantity < priceTable[i]["rangeMax"]
  //   ) {
  //     itemPrice = Object.values(priceTable[i])[2];
  //     break;
  //   }
  // }
  let total = itemPrice * quantity;
  // fix total amount by conditions
  if (quantity < priceJS.min_quantity && quantity > 1) {
    total += setupFee;
  }
  //for custom product only
  //Tearoff
  const Tearoff = findObjectInArray(variants, "Tearoff");
  if (Tearoff > 0) {
    total += priceJS.tearoff * quantity;
    total += Tearoff > 1 ? priceJS.additional_tearoff * Tearoff : 0;
  }
  //Personalized
  const Personalized = findObjectInArray(variants, "Personalized");
  if (Personalized === "true") {
    total += priceJS.personalize_start + priceJS.personalize_per * quantity;
  }
  //QR Code/Barcode
  const Barcode = findObjectInArray(variants, "Barcodes/QR Codes");
  if (
    Barcode === "true" &&
    (Personalized === "false" || isNull(Personalized))
  ) {
    total += priceJS.barcode * quantity;
  }

  let blackColor = "[0,0,0,100]";
  let whiteColor = "[0,0,0,0]";
  blackColor = blackColor.replace(/\s/g, "");
  whiteColor = whiteColor.replace(/\s/g, "");

  //image and text color, default color not charged
  const customImage = findObjectInArray(variants, "Upload Logo");
  const customText = findObjectInArray(variants, "Add Text");
  let chargeColor = 0;
  // if (customImage) {
  //   const hasNonDefaultColor = customImage.some(
  //     (obj) => obj.colour !== whiteColor && obj.colour !== blackColor
  //   );
  //   if (hasNonDefaultColor) {
  //     chargeColor = priceJS.custom_color;
  //   }
  // }

  if (customText) {
    const hasNonDefaultColor = customText.some((obj) => {
      return obj.colour !== whiteColor && obj.colour !== blackColor;
    });
    if (hasNonDefaultColor) {
      chargeColor = priceJS.custom_color;
    }
  }
  total += chargeColor;

  //Print On The Back, default color not charged
  let PrintOnBack = findObjectInArray(variants, "Print On The Back");
  if (
    !isNull(PrintOnBack) &&
    PrintOnBack !== whiteColor &&
    PrintOnBack !== blackColor &&
    PrintOnBack !== "false"
  ) {
    total += priceJS.backPrint * quantity;
  }

  total = total === 0 ? itemPrice : total;

  //check custom image for custom product
  const customImgSRC = getCustomImage(
    imgFolder,
    customText,
    customImage,
    Personalized,
    Barcode,
    Tearoff
  );

  return { total: Number(total).toFixed(2), customImgSRC };
};

export const findProductArrIncategories = (categoryArray, category_id) => {
  const targetcategory = categoryArray.find(
    (category) => category.category_id === category_id
  );
  const targetProducts = targetcategory ? targetcategory.product : [];
  return targetProducts;
};

export const hexToCmyk = (colorString) => {
  if (colorString === "false") {
    return "false";
  }
  const cmyk = convert.hex.cmyk(colorString);
  return JSON.stringify(cmyk);
};

export const cmykToHex = (colorString) => {
  if (colorString === "false") {
    return "false";
  }
  let str = colorString;
  if (typeof str === "string") {
    str = JSON.parse(str);
  }
  const hex = convert.cmyk.hex(str);
  return "#" + hex;
};

export const calculateWeight = (weight, weightQuantity, quantity) => {
  const w = Number(weight);
  const wq = Number(weightQuantity);
  const q = Number(quantity);

  if (wq > 1) {
    //this is bib numbers with weightQuantity=100
    const multiplier = Math.ceil(q / wq);
    return (w * multiplier).toFixed(2);
  } else {
    //this is normal product with weightQuantity=1
    return (w * q).toFixed(2);
  }
};

export const listenBibSnE = (handleNumberChange, numberChangeRegister) => {
  let attempt = 0;
  let attempt_fake = 0;
  const maxAttempts = 3;
  const maxAttempts_fake = 3;
  const checkForElement = () => {
    const endInput = document.getElementById("v-End-Number-input");
    const startInput = document.getElementById("v-Start-Number-input");
    if (endInput && startInput && !numberChangeRegister) {
      endInput.addEventListener("input", handleNumberChange);
      startInput.addEventListener("input", handleNumberChange);
    } else if (attempt < maxAttempts) {
      // Element not found yet, try again after a delay
      setTimeout(checkForElement, 500);
      attempt += 1;
    }
  };

  const fakePlaceHolder = () => {
    const endInput = document.getElementById("v-End-Number-input");
    const startInput = document.getElementById("v-Start-Number-input");
    if (endInput && startInput && !numberChangeRegister) {
      endInput.addEventListener("focus", function (event) {
        var inputValue = event.target.value;
        if (inputValue === "0") {
          event.target.value = ""; // Empty the value
        }
      });
      startInput.addEventListener("focus", function (event) {
        var inputValue = event.target.value;
        if (inputValue === "0") {
          event.target.value = ""; // Empty the value
        }
      });
    } else if (attempt_fake < maxAttempts_fake) {
      // Element not found yet, try again after a delay
      setTimeout(fakePlaceHolder, 500);
      attempt_fake += 1;
    }
  };

  if (!numberChangeRegister) {
    checkForElement();
    fakePlaceHolder();
  }
};

export const sizeCheck = (size) => {
  let w = size.split("x")[0];
  let h = size.split("x")[1];
  if (h.includes(" ")) {
    h = h.split(" ")[0];
  }
  return Number(w) - Number(h) === 0 ? 100 : 70; //100% or 70%
};

export const getCustomImage = (
  imgFolder,
  text,
  logo,
  personalized,
  qr,
  tearoff
) => {
  const imageConfig = {
    11110: "text_logo_personalized_qr.png", // logo, text, qr, personalized
    11100: "text_logo_qr.png", // logo, text, qr
    11010: "text_logo_personalized.png", // logo, text, personalized
    "01110": "logo_personalized_qr.png", // logo, qr, personalized
    10110: "text_personalized_qr.png", // text, qr, personalized
    11000: "text_logo.png", // logo, text
    "01100": "logo_qr.png", // logo, qr
    "01010": "logo_personalized.png", // logo, personalized
    10010: "text_personalized.png", // text, personalized
    10100: "text_qr.png", // text, qr
    //"00110": "qr_personalized.png", // qr, personalized
    "01000": "logo.png", // logo only
    10000: "text.png", // text only
    //"00010": "qr.png", // qr only
    //"00100": "personalized.png", // personalized only
    10101: "text_qr_tearoff.png",
    "00000": "main_pic.png", // none
  };

  // Create a key based on the presence of each parameter
  const key =
    (text !== undefined && text.length > 0 ? "1" : "0") +
    (logo !== undefined && logo.length > 0 ? "1" : "0") +
    (qr === "true" ? "1" : "0") +
    (personalized === "true" ? "1" : "0") +
    (tearoff !== undefined && tearoff > 0 ? "1" : "0");

  // Retrieve the corresponding image URL from the imageConfig object
  const imageUrl = imgFolder + "/" + (imageConfig[key] || "main_pic.png");
  return imageUrl;
};
