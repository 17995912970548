import React, { useState } from "react";

const VariantsTextarea = (props) => {
  const [value, setValue] = useState(props.init[0].name ?? 0);

  const handleChange = (value) => {
    setValue(value);
    props.setVariants(props.name, value);
  };

  return (
    <div id={`v-${props.name.replace(/\s+/g, "-")}`}>
      <h3 className="text-sm font-medium capitalize">{props.name}</h3>
      <textarea
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        max={200}
        rows={4}
        className="mt-1 p-2 block w-full border border-gray-200 rounded-md outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
        placeholder="For multiple bib number ranges, provide details (color, number sequence) here. (eg. 1-200 red background, 301-400 green background)"
      />
    </div>
  );
};

export default VariantsTextarea;
